import React from 'react';
import './App.css';
import Autosuggest from 'react-autosuggest';
import api from './api';
import DateTimePicker from 'react-datetime-picker';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id : '',
      loggedIn : false,
      suggestions : [],
      location:'',
      startDate:new Date(),
      endDate : new Date(),
    }
  }

  onChangeStartDate = startDate => this.setState({ startDate })
  onChangeEndDate = endDate=> this.setState({ endDate})

  setValue = (e) => {
    this.setState({
      [e.target.name] : e.target.value,
    });
    
  }

  login = (e) => {
    this.setState({
      loggedIn :true,
    })
  }


  onSuggestionsFetchRequested = ({ value }) => {
    if(value.trim());

    if(value.length > 1) {
      api.get('locations.php?s='+value)
      .then(res=> {
        if(res.data.success === true) {
          console.log(res.data);
          if(res.data.suggestions !== null) {
            this.setState({
              suggestions : res.data.suggestions,
            });
          }
        }
      })
      .catch(e=> console.log(e));

    } else {
      this.setState({
        suggestions:[],
      })
    }

    // this.setState({
    //   suggestions: getSuggestions(value)
    // });
  };
 
  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onChange = (event, { newValue }) => {
    this.setState({
      location: newValue
    });
  };

  getDateString = (date) => {
    var value = date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate() + " " + date.getHours() + ":" + date.getMinutes();
    return value;
  
  }

  getFile = () => {
    var startdate = this.getDateString(this.state.startDate);
    var enddate= this.getDateString(this.state.endDate);
    window.location.href='https://qr.brainpill.io/api/gov/download.php?l='+this.state.location+'&start='+startdate+'&end='+enddate;

  }

  render() {
    if(this.state.loggedIn) {

      const value = this.state.location;
      return (
        <div className="Main">
          <div>
            <div className="blob">
              <div className="title">
                장소명(매장명)
              </div>
              <Autosuggest
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={(value) => value}
                renderSuggestion={(value) => value}
                inputProps={{
                  placeholder : '장소명을 입력해주세요',
                  value,
                  onChange:this.onChange

                  
                }}
              />

            </div>
            <div className="blob">
              <div className="title">
                조회 시간 범위
                
              </div>
              <DateTimePicker
                onChange={this.onChangeStartDate}
                value={this.state.startDate}
                format={"y년 M월 d일 H시 m분"}
                disableClock={true}
                className="datetime"
                clearIcon = {null}
                calendarIcon = {null}
              />
              <div className="spacer"></div>
              <DateTimePicker
                onChange={this.onChangeEndDate}
                value={this.state.endDate}
                minDate = {this.state.startDate}
                format={"y년 M월 d일 H시 m분"}
                disableClock={true}
                className="datetime"
                clearIcon = {null}
                calendarIcon = {null}
              />
              

            </div>
            <div className="download">
              <button onClick={this.getFile}>엑셀 다운로드</button>
              
            </div>
          </div>
          
        </div>

      );

    } else {
      return (
        <div className="Login">
          <div>
            <div className="Logo">
            </div>
            <div className="inputs">
              <input type="text" placeholder="아이디" name="id" value={this.state.id} onChange={this.setValue} />
              <input type="password" placeholder="비밀번호"/>

              <button onClick={this.login}>로그인</button>
            </div>
          </div>
        </div>
      );

    }

  }
}

export default App;
